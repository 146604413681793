var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BasePage",
    [
      _c("NavigationBar"),
      _vm._v(" "),
      _c("Body"),
      _vm._v(" "),
      _vm.activeModal
        ? _c(
            _vm.modalComponent,
            _vm._b({ tag: "component" }, "component", _vm.modalProps, false)
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }