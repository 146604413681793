<template>
  <BaseBody class="base-body">
    <BaseHeader :title="uiStrings.SETTINGS_PAGE_HEADER" />
    <div id="contactform">
      <ContactForm />
    </div>
  </BaseBody>
</template>

<script>
import BaseBody from '../../components/BaseBody.vue';
import BaseHeader from '../../components/BaseHeader.vue';
import ContactForm from '../../components/ContactForm.vue';

export default {
  name: 'Contact',

  components: {
    ContactForm,
    BaseBody,
    BaseHeader,
  },

  computed: {
  },

  methods: {
  },
};
</script>

<style scoped>
.base-body {
  grid-template-rows: auto 1fr;
}

.loading-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings {
  overflow-y: auto;
  display: grid;
  grid-gap: 1em;
  grid-auto-rows: min-content;
  grid-template-columns: minmax(auto, 600px);
}

.system-information-label {
  font-size: 1.25em;
  margin: 0 0 3px 0;
  color: #222222;
  font-weight: 400;
}

.system-information-content {
  font-size: 0.875em;
  white-space: pre-line;
}

.visibility-toggle {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
}
</style>
