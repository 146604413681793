<template>
  <div class="body">
    <keep-alive>
      <component :is="page" />
    </keep-alive>
  </div>
</template>

<script>
import { state as rootState } from '../stores/root';
import Introduction from './Webgui.vue';
import Contact from './Settings.vue';

export default {
  name: 'Body',

  components: {
    Introduction,
    Contact,
  },

  computed: {
    page: () => rootState.page,
  },
};
</script>

<style scoped>
.body {
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}
</style>
