<template>
  <li
    :class="itemClass"
    @click="$emit('click', item)"
  >
    {{ item }}
  </li>
</template>

<script>
export default {
  name: 'MenuItem',

  props: {
    item: {
      type: String,
      required: true,
    },

    selected: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    itemClass() {
      return ['item', {
        selected: this.selected,
        disabled: this.disabled,
      }];
    },
  },
};
</script>

<style scoped>
.item {
  line-height: 2;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item:hover {
  color: #ddd;
}

.item.selected {
  color: #4295FE;
  pointer-events: none;
}

.item.disabled {
  color: #999;
  pointer-events: none;
}
</style>
