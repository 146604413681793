<template>
  <div class="container">
    <div class="bar">
      <ButtonIcon
        iconName="menu"
        @click.stop="toggleNavigationMenu"
      >
        <IconMenu />
      </ButtonIcon>

      <ButtonIcon
        v-show="page === 'Home'"
        iconName="display"
        @click="onDisplayClick"
      >
        <IconDisplay />
      </ButtonIcon>
    </div>
    <ul
      v-show="menuOpen"
      v-on-clickaway="onMenuClickaway"
      class="menu"
    >
      <MenuItem
        item="Introduction"
        :selected="page === 'Introduction'"
        @click="onItemClick"
      />
      <MenuItem
        item="Contact"
        :selected="page === 'Contact'"
        @click="onItemClick"
      />
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { state as rootState, actions as rootActions } from '../stores/root';
/* import { actions as webguiActions } from '../stores/webgui'; */
import ButtonIcon from '../../components/ButtonIcon.vue';
import IconMenu from '../../components/icons/IconMenu.vue';
import MenuItem from '../../components/MenuItem.vue';

export default {
  name: 'NavigationBar',

  components: {
    ButtonIcon,
    IconMenu,
    MenuItem,
  },

  mixins: [
    clickaway,
  ],

  computed: {
    page: () => rootState.page,
    menuOpen: () => rootState.navigationMenuOpen,
  },

  created() {
    this.menuItems = ['SystemInfo', 'Contact'];
  },

  methods: {
    onItemClick(page) {
      rootActions.goTo({ page });
    },

    toggleNavigationMenu() {
      rootActions.toggleNavigationMenu();
    },

    onDisplayClick() {
      /* webguiActions.cycleDisplayMode(); */
    },

    onMenuClickaway() {
      rootState.navigationMenuOpen = false;
    },

    getItemClass(item) {
      return ['item', {
        selected: this.page === item,
        disabled: item !== 'Introduction' && item !== 'Contact',
      }];
    },

    onReloadAppClick() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  z-index: 2;
}

.bar {
  color: white;
  width: 100%;
  height: 72px;
  padding: 0 2em;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.9);
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  grid-gap: 2em;
  align-items: center;
  justify-items: center;
}

.bar > *:first-child {
  justify-self: start;
}

.bar > *:last-child {
  justify-self: end;
}

.menu {
  font-size: 2em;
  font-weight: 600;
  color: white;
  list-style-type: none;
  position: absolute;
  width: 70%;
  max-width: 300px;
  border-radius: 6px;
  padding: 1em;
  background: rgba(0, 0, 0, 0.9);
  margin-block-start: 0.5rem;
  margin-block-end: 0;
}
</style>
