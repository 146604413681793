var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button",
      attrs: { disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "BaseIcon",
        {
          attrs: {
            iconName: _vm.iconName,
            width: _vm.width,
            height: _vm.height,
            iconColor: _vm.iconColor,
            animation: _vm.animation,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }