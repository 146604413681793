import { en } from '../constants/uiStrings';

const install = function (Vue) {
  Vue.mixin({
    created() {
      this.uiStrings = en;
    },
  });
};

export default {
  install,
};
