<template>
  <BaseBody class="base-body">
    <BaseHeader :title="uiStrings.WEBGUI_PAGE_HEADER" />
    <div class="webgui">
      <h2>Terrestrial and Non-Terrestrial Telecommunication and Wireless Communications:</h2>
      <p>Transmission System like SDH. Cellular network from Analog infrastructure to
        Digital System - GSM-CDMA-WCDMA-OFDM (3G-4G-5G).</p>
      <p>Satellite Communication: INMARSAT BGAN (3GPP protocol)</p>
      <p>RCM - RadarSat Constellation Mission - Synthetic Aperture Radar (there is no on-board
        processing raw data is processed at ground) AIS Payloads of XXXX, XXX</p>
      <h2>Automotive:</h2>
      <p>Autosar, ADAS (camera, radar).</p>
      <h2>Other embedded systems include but not limited to:</h2>
      <p>Data Acquisition, Ground Penetrating Radar, RFID,
        Telemetric of Smart Meter (utility IOT), etc.</p>
      <h2>Programming Language Used in the projects:</h2>
      <p>C/C++, Cuda, Python, Java, Javascripts, TCL, Bash script, etc.</p>
      <p>OSs include Linux (mainly Ubuntu-non-preemptive, preemptive, RealTimeOS), Windows,
        freeRTOS, VxWorks, Integrity, QNX.</p>
      <p>Software tools: Yocto, buildroot, Google Unit Test, Bazel build system, etc.</p>
      <h2>Signal Processing:</h2>
      <p>Includes classical and its extension of machine learning (ML) and edge AI. ML is one of AI.
        Also knowledgeable in other AI.</p>
      <p>Familiar with ISO 7 layers model vs TCP/IP model.</p>
      <h2>Board Bring Up and Debug</h2>
      <h2>Implement 5G using SDR</h2>
      <p>LTE network supporting 4G and 5G for private network.</p>
      <h2>Lab:</h2>
      <p>3D printer, CNC (PCB milling), Laser etch/cutting for fast prototyping, DC power supply,
        oscilloscope, software define radio platform, and spectrum instrument.</p>
    </div>
  </BaseBody>
</template>

<script>
/* import { state as settingsState } from '../stores/settings'; */
import { state as webguiState } from '../stores/webgui';
import BaseBody from '../../components/BaseBody.vue';
import BaseHeader from '../../components/BaseHeader.vue';

export default {
  name: 'Introduction',

  components: {
    BaseBody,
    BaseHeader,
  },

  computed: {
    loading: () => webguiState.loading,
    updates: () => webguiState.updates,
    Gateway: () => webguiState.Gateway,
  },
};
</script>

<style scoped>
.base-body {
  grid-template-rows: auto 1fr;
}

.webgui {
  overflow-y: auto;
  display: grid;
  grid-gap: 1em;
  grid-auto-rows: min-content;
  grid-template-columns: minmax(auto, 600px);
}
</style>
