var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseBody",
    { staticClass: "base-body" },
    [
      _c("BaseHeader", { attrs: { title: _vm.uiStrings.WEBGUI_PAGE_HEADER } }),
      _vm._v(" "),
      _c("div", { staticClass: "webgui" }, [
        _c("h2", [
          _vm._v(
            "Terrestrial and Non-Terrestrial Telecommunication and Wireless Communications:"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Transmission System like SDH. Cellular network from Analog infrastructure to\n      Digital System - GSM-CDMA-WCDMA-OFDM (3G-4G-5G)."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Satellite Communication: INMARSAT BGAN (3GPP protocol)"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "RCM - RadarSat Constellation Mission - Synthetic Aperture Radar (there is no on-board\n      processing raw data is processed at ground) AIS Payloads of XXXX, XXX"
          ),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Automotive:")]),
        _vm._v(" "),
        _c("p", [_vm._v("Autosar, ADAS (camera, radar).")]),
        _vm._v(" "),
        _c("h2", [
          _vm._v("Other embedded systems include but not limited to:"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Data Acquisition, Ground Penetrating Radar, RFID,\n      Telemetric of Smart Meter (utility IOT), etc."
          ),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Programming Language Used in the projects:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "C/C++, Cuda, Python, Java, Javascripts, TCL, Bash script, etc."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "OSs include Linux (mainly Ubuntu-non-preemptive, preemptive, RealTimeOS), Windows,\n      freeRTOS, VxWorks, Integrity, QNX."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Software tools: Yocto, buildroot, Google Unit Test, Bazel build system, etc."
          ),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Signal Processing:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Includes classical and its extension of machine learning (ML) and edge AI. ML is one of AI.\n      Also knowledgeable in other AI."
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("Familiar with ISO 7 layers model vs TCP/IP model.")]),
        _vm._v(" "),
        _c("h2", [_vm._v("Board Bring Up and Debug")]),
        _vm._v(" "),
        _c("h2", [_vm._v("Implement 5G using SDR")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("LTE network supporting 4G and 5G for private network."),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Lab:")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3D printer, CNC (PCB milling), Laser etch/cutting for fast prototyping, DC power supply,\n      oscilloscope, software define radio platform, and spectrum instrument."
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }