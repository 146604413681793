var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: _vm.itemClass,
      on: {
        click: function ($event) {
          return _vm.$emit("click", _vm.item)
        },
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.item) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }