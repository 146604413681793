<template>
  <div class="contact-form">
    <h1>Contact Us</h1>
    <form @submit.prevent="submitForm">
      <div>
        <label for="name">Name:</label>
        <input id="name" v-model="formData.name" type="text" required>
      </div>
      <div>
        <label for="email">Email:</label>
        <input id="email" v-model="formData.email" type="email" required>
      </div>
      <div>
        <label for="message">Message:</label>
        <textarea id="message" v-model="formData.message" required></textarea>
      </div>

      <button type="submit" :disabled="loading">
        {{ loading ? 'Sending...' : 'Send' }}
      </button>

    </form>

    <p v-if="responseMessage">
      {{ responseMessage }}
    </p>

  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: '',
      },
      responseMessage: '',
      loading: false,
      success: false,
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      this.responseMessage = '';
      this.success = false;
      console.log('Submitting form:', this.formData); // Debug log
      try {
        const response = await fetch('sendEmail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.formData),
        });

        console.log('Response:', response); // Debug log

        if (response.ok) {
          this.responseMessage = 'Your message has been sent successfully!';
          this.success = true;
          this.formData.name = '';
          this.formData.email = '';
          this.formData.message = '';
        } else {
          const errorData = await response.json();
          this.responseMessage = errorData.message || 'There was an error sending your message.';
          this.success = false;
        }
      } catch (error) {
        console.error('Fetch error:', error); // Debug log
        this.responseMessage = 'There was an error sending your message. Please try again later.';
        this.success = false;
      } finally {
        this.loading = false;
      }

      this.formData.name = '';
      this.formData.email = '';
      this.formData.message = '';
    },
  },
};
</script>

<style>
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form div {
  margin-bottom: 15px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}
</style>
