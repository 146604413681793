var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    { attrs: { transform: "matrix(1.785714, 0, 0, 1.785714, 0, 0.9)" } },
    [
      _c("rect", { attrs: { width: "10.08", height: "1.19", rx: "0.6" } }),
      _vm._v(" "),
      _c("rect", {
        attrs: { y: "4.05", width: "10.08", height: "1.19", rx: "0.6" },
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: { y: "7.86", width: "10.08", height: "1.19", rx: "0.6" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }