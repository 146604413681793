var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact-form" }, [
    _c("h1", [_vm._v("Contact Us")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _c("div", [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Name:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.name,
                expression: "formData.name",
              },
            ],
            attrs: { id: "name", type: "text", required: "" },
            domProps: { value: _vm.formData.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "name", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { attrs: { for: "email" } }, [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.email,
                expression: "formData.email",
              },
            ],
            attrs: { id: "email", type: "email", required: "" },
            domProps: { value: _vm.formData.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "email", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { attrs: { for: "message" } }, [_vm._v("Message:")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.message,
                expression: "formData.message",
              },
            ],
            attrs: { id: "message", required: "" },
            domProps: { value: _vm.formData.message },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "message", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("button", { attrs: { type: "submit", disabled: _vm.loading } }, [
          _vm._v(
            "\n      " + _vm._s(_vm.loading ? "Sending..." : "Send") + "\n    "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.responseMessage
      ? _c("p", [_vm._v("\n    " + _vm._s(_vm.responseMessage) + "\n  ")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }