<template>
  <BasePage>
    <NavigationBar />
    <Body />
    <component
      :is="modalComponent"
      v-if="activeModal"
      v-bind="modalProps"
    />
  </BasePage>
</template>

<script>
import { state as rootState } from './stores/root';
import NavigationBar from './views/NavigationBar.vue';
import Body from './views/Body.vue';
import BasePage from '../components/BasePage.vue';

export default {
  name: 'App',

  components: {
    NavigationBar,
    Body,
    BasePage,
  },

  computed: {
    page: () => rootState.page,
    activeModal: () => rootState.activeModal,
    modalProps: () => rootState.modalProps,

    modalComponent() {
      return this.importModalComponent(this.activeModal);
    },
  },

  mounted() {
  },

  methods: {
    importModalComponent(modalName) {
      // eslint-disable-next-line prefer-template
      return () => import('./views/modals/' + modalName + '.vue');
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html, body, #root {
  position: relative;
  width: 100%;
  height: 100%;
}

body {
  background-color: #f5f5f5;
  color: #222222;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
}

.v-notices {
  top: 72px !important;
  padding: 0.5em !important;
  white-space: pre-wrap !important;
}
</style>
