<template>
  <button
    class="button"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <BaseIcon
      :iconName="iconName"
      :width="width"
      :height="height"
      :iconColor="iconColor"
      :animation="animation"
    >
      <slot />
    </BaseIcon>
  </button>
</template>

<script>
import BaseIcon from './BaseIcon.vue';

export default {
  name: 'ButtonIcon',

  components: {
    BaseIcon,
  },

  props: {
    iconName: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 24,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
    animation: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  color: inherit;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
  cursor: auto;
}
</style>
