<template>
  <div class="page">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BasePage',
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  padding: 0.25em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
