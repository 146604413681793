var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "bar" },
      [
        _c(
          "ButtonIcon",
          {
            attrs: { iconName: "menu" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleNavigationMenu.apply(null, arguments)
              },
            },
          },
          [_c("IconMenu")],
          1
        ),
        _vm._v(" "),
        _c(
          "ButtonIcon",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.page === "Home",
                expression: "page === 'Home'",
              },
            ],
            attrs: { iconName: "display" },
            on: { click: _vm.onDisplayClick },
          },
          [_c("IconDisplay")],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.menuOpen,
            expression: "menuOpen",
          },
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.onMenuClickaway,
            expression: "onMenuClickaway",
          },
        ],
        staticClass: "menu",
      },
      [
        _c("MenuItem", {
          attrs: {
            item: "Introduction",
            selected: _vm.page === "Introduction",
          },
          on: { click: _vm.onItemClick },
        }),
        _vm._v(" "),
        _c("MenuItem", {
          attrs: { item: "Contact", selected: _vm.page === "Contact" },
          on: { click: _vm.onItemClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }