/* eslint-disable import/no-cycle */
import Vue from 'vue';

export const defaultSettings = {
};

export const state = Vue.observable({
});

export const actions = {
};

export default {
  state,
  actions,
};
