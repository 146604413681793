const shortBeep = new Audio('beep-short.mp3');
const longBeep = new Audio('beep-long.mp3');
const doubleBeep = new Audio('beep-double.mp3');

export function playShortBeep() {
  shortBeep.play();
}

export function playLongBeep() {
  longBeep.play();
}

export function playDoubleBeep() {
  doubleBeep.play();
}

export function playMutedBeep() {
  [shortBeep, longBeep, doubleBeep].forEach(beep => {
    beep.addEventListener('ended', () => {
      beep.muted = false;
    }, { once: true });

    beep.muted = true;
    beep.play();
  });
}
