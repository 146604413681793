var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseBody",
    { staticClass: "base-body" },
    [
      _c("BaseHeader", {
        attrs: { title: _vm.uiStrings.SETTINGS_PAGE_HEADER },
      }),
      _vm._v(" "),
      _c("div", { attrs: { id: "contactform" } }, [_c("ContactForm")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }