<template>
  <div class="header">
    <h3 class="title">
      {{ title }}
    </h3>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseHeader',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin: 0;
  font-weight: 500;
  font-size: 2em;
}
</style>
