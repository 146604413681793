export const en = Object.freeze({
  START_BUTTON: 'Start',
  SAVE_BUTTON: 'Save',
  FINISH_BUTTOM: 'Finish',
  CANCEL_BUTTON: 'Cancel',
  SETTINGS_PAGE_HEADER: 'How to contact',
  WEBGUI_PAGE_HEADER: 'Experience and Knowledge',
  ACCESS_POINT_FIELD: 'Access Point Name',
  IMEI_FIELD: 'Terrestrial and Non-Terrestrial Telecommunication and Wireless Communications',
  ICCID_FIELD: 'Automotive',
  SIM_PIN_FIELD: 'SIM PIN',
  SIM_SELECT_FIELD: 'Choose SIM card',
  AUTO_CONNECT_FIELD: 'Network Auto Connection',
  IPV4_IPV6_FIELD: 'Choose IP Version',
  IPAddress_FIELD: 'Other embedded systems include but not limited to',
  Gateway_FIELD: 'Programming Languages',
  DNS_FIELD: 'Signal Processing',
  MTU_FIELD: 'Board Bring Up and Debug',
  PCSide_Config_FIELD: 'USB Ethernet IP Configuration',
  POWER_OFF_TIMEOUT_FIELD: 'Power Off Timeout',
  POWER_OFF_TIMEOUT_HELP_TEXT: 'Minutes of inactivity. Enter 0 to disable timeout.',
  SAVE_SETTINGS_BUTTON: 'Save Settings',
  RESET_SETTINGS_BUTTON: 'Reset to Factory Settings',
  UPDATE_FIRMWARE_BUTTON: 'Update Firmware',
  UPDATE_SYSTEM_FILE_BUTTON: 'Update System File',
  POWER_OFF_BUTTON: 'Power Off',
  SYSTEM_INFORMATION_FIELD: 'System Information',
  FIRMWARE_VERSION_LABEL: 'Firmware Version',
  FIRMWARE_VERSION_FIELD: 'Implement 5G using SDR',
  LIVEUMODEM_SERIAL_FIELD: 'Lab',
  IMEI1_LABEL: 'IMEI1',
  IMEI2_LABEL: 'IMEI2',
  ICCID1_LABEL: 'Autosar, ADAS (camera, radar)',
  ICCID2_LABEL: 'ICCID2',
  SYSTEM_SERIAL_NUMBER_LABEL: 'System Serial Number',
  SAVE_SYSTEM_LOG_BUTTON: 'Save System Log',
  SAVE_SETTINGS_LOADING_MESSAGE: 'Saving settings...',
  SAVE_SETTINGS_ERROR_MESSAGE: 'Failed to save settings',
  SAVE_SETTINGS_MODAL_MESSAGE: `Unsaved changes were made to the settings. Save them now?`,
  RESET_SETTINGS_LOADING_MESSAGE: 'Resetting to factory settings...',
  RESET_SETTINGS_ERROR_MESSAGE: 'Failed to reset to factory settings',
  RESET_SETTINGS_MODAL_MESSAGE: `Reset to factory settings? This will require the system to reboot.`,
  POWER_OFF_MODAL_MESSAGE: 'You are about to power off the system. Do you want to proceed?',
  POWER_OFF_SUCCESS_MESSAGE: 'The system is powering off, you can close the browser.',
  POWER_OFF_LOADING_MESSGE: 'Powering off...',
  POWER_OFF_ERROR_MESSAGE: 'Failed to power off',
  UPDATE_SYSTEM_FILE_MODAL_MESSAGE: `You are about to update the system file. Do you want to proceed?`,
  UPDATE_SYSTEM_FILE_LOADING_MESSAGE: 'Updating system file...',
  UPDATE_SYSTEM_FILE_SUCCESS_MESSAGE: 'Successfully updated system file.',
  UPDATE_SYSTEM_FILE_ERROR_MESSAGE: 'Failed to update system file',
  SAVE_SYSTEM_LOG_ERROR_MESSAGE: 'Failed to save system log',
  RELOAD_APP_NAV_ITEM: 'Reload App',
  UPDATE_FIRMWARE_MODAL_MESSAGE: `Updating firmware will require a system reboot. Ensure the system battery has at least 20% charge, and do not power off during the update. Do you want to proceed?`,
  UPDATE_FIRMWARE_LOADING_MESSAGE: 'Updating system firmware...',
  UPDATE_FIRMWARE_SUCCESS_MESSAGE: 'Firmware updated successully, now rebooting system.',
  UPDATE_FIRMWARE_ERROR_MESSAGE: 'Firmware update failed',
  ACCESS_POINT_CHANGED_MODAL_TITLE: 'Cellular Network Access Point Changed',
  ACCESS_POINT_CHANGED_MODAL_MESSAGE: `To continue, please connect your device to the new access point.`,
  ACCESS_POINT_CHANGED_MODAL_SSID: 'Name',
  ACCESS_POINT_CHANGED_MODAL_PASSWORD: 'Password',
  WAIT_FOR_REBOOT_MODAL_TITLE: 'System is Rebooting',
  WAIT_FOR_REBOOT_MODAL_MESSAGE: `If your device does not reconnect to cellular network or reconnect to the host after a minute, please check your cellular network settings and the usb gadget settings to ensure the correct APN is selected.`,
  NAME_FIELD: 'Name',
  LABEL_FIELD: 'Label',
  SYSTEM_CONFIGURATION_FAILED: 'System configuration error, please try rebooting the device.',
  SYSTEM_DISCONNECTED_STATUS: 'Disconnected',
});
