/* eslint-disable import/no-cycle */
import Vue from 'vue';
import { playShortBeep, playLongBeep, playDoubleBeep, playMutedBeep } from '../../utils/beep';
import { state as settingsState } from './settings';

export const state = Vue.observable({
  page: 'Introduction',
  navigationMenuOpen: false,
  activeModal: null,
  modalProps: {},
  mockApi: false,
  previousError: null,
});

export const actions = {
  goTo({ page }) {
    state.page = page;
    state.navigationMenuOpen = false;
  },

  toggleNavigationMenu() {
    state.navigationMenuOpen = !state.navigationMenuOpen;
  },

  openModal({ type, props = {} }) {
    state.activeModal = type;
    state.modalProps = props;
  },

  closeModal() {
    state.activeModal = null;
    state.modalProps = {};
  },

  // eslint-disable-next-line max-len
  async userAction({ action, payload = {}, loadingMessage, successMessage, errorMessage, keepOpen }) {
    if (loadingMessage) {
      actions.openModal({ type: 'LoadingUserActionModal', props: { message: loadingMessage } });
    }

    try {
      await action(payload);

      if (!keepOpen) {
        actions.closeModal();
      }

      if (successMessage) {
        Vue.$toast.open({ message: successMessage, type: 'info' });
      }
    } catch ({ error }) {
      actions.closeModal();
      actions.playDoubleBeep();

      if (errorMessage) {
        const message = `${errorMessage}: ${error}`;
        Vue.$toast.open({ message, type: 'error' });
      }

      return Promise.reject(error);
    }
  },

  handleCommandError({ message }) {
    actions.playDoubleBeep();
    Vue.$toast.open({ message, type: 'error' });
  },

  playShortBeep() {
    if (settingsState.settings.quietMode) {
      return;
    }

    playShortBeep();
  },

  playLongBeep() {
    if (settingsState.settings.quietMode) {
      return;
    }

    playLongBeep();
  },

  playDoubleBeep() {
    if (settingsState.settings.quietMode) {
      return;
    }

    playDoubleBeep();
  },

  whitelistNotificationBeep() {
    playMutedBeep();
  },
};

export default {
  state,
  actions,
};
