<template>
  <svg
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    role="presentation"
    :class="animationClass"
  >
    <title
      :id="iconName"
      lang="en"
    >
      {{ iconName }} icon
    </title>
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    iconName: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 24,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 24,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
    animation: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    animationClass() {
      if (this.animation === 'spin') {
        return 'spin';
      }

      if (this.animation === 'blink') {
        return 'blink';
      }

      return null;
    },
  },
};
</script>

<style scoped>
.spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.blink {
  animation: blink 2s infinite linear;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
