var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.animationClass,
      attrs: {
        width: _vm.width,
        height: _vm.height,
        "aria-labelledby": _vm.iconName,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 18 18",
        role: "presentation",
      },
    },
    [
      _c("title", { attrs: { id: _vm.iconName, lang: "en" } }, [
        _vm._v("\n    " + _vm._s(_vm.iconName) + " icon\n  "),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: _vm.iconColor } }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }