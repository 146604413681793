/* eslint-disable import/no-cycle */
import Vue from 'vue';

export const state = Vue.observable({
  loading: false,
  updates: {},
});

export const actions = {
};

export default {
  state,
  actions,
};
