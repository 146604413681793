import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import UIStringsPlugin from '../plugins/UIStringsPlugin';
import App from './App.vue';
import 'vue-toast-notification/dist/theme-sugar.css';

export default () => {
  Vue.use(VueToast, { position: 'top-right', duration: 5000 });
  Vue.use(UIStringsPlugin);

  // eslint-disable-next-line no-new
  new Vue({
    el: '#app',
    render: h => h(App),
  });
};
