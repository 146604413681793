<template>
  <div class="body">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseBody',
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 1em;
  padding: 0.5em;
}
</style>
